:root {
  --color-blue: #3baeda;
  --color-green: #a0d468;
  --color-light-green: #F2FCE8;
  --color-dark-green: #164a05;
  --color-red: #e7563e;
  --color-light-red: #fcebe8;
  --color-dark-red: #780000;
  --color-yellow: #fbc73d;
  --color-light-yellow: #ffffdd;
  --color-dark-yellow: #6e3a01;
  --color-grey: #aab2bd;
  --color-light-grey: #edf2f6;
  --color-dark-grey: #a9a9a9;
  --color-orange: #FF8000;
  --color-dark-orange: #994C00;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: var(--color-grey);
}

.none {
  display: none;
}

.packId {
  text-align: right;
  font-size: 0.9rem;
  margin-right: 0px;
  margin-bottom: 3px;
}

.card {
  width: 100%;
  height: 100%;
  position: relative;
  border: solid 1px var(--color-light-grey);
  box-shadow: 0 0 10px -3px var(--color-light-grey);
  padding: 10px 10px 20px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.card.active.notMonitored {
  border: solid 1px var(--color-red);
  box-shadow: 0 0 10px -3px var(--color-red);
}

.packBody {
  flex: 1 1 auto;
  height: 50%;
}

.statusCircle {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
}

.statusCircle.monitored {
  background-color: var(--color-green);
}

.statusCircle.notMonitored {
  background-color: var(--color-red);
}
.statusCircle.notMonitored.finished {
  background-color: var(--color-grey);
}

.statusBattery {
  width: 15px;
  margin: 2px 0 0 3px;
}

.statusMessage {
  color: var(--color-red);
  padding-left: 8px;
  vertical-align: bottom;
  display: inline-block;
  font-size: 1rem;
  height: 1.6rem;
  position: absolute;
  white-space: nowrap;
  font-weight: 500;
}

.statusCircle.monitored .statusMessage {
  color: var(--color-green);
}
.statusCircle.notMonitored.finished .statusMessage {
  color: var(--color-grey);
}

.header {
  text-align: center;
  cursor: pointer;
  font-size: 1.5em;
  font-weight: bold;
}

.header.active {
  color: black;
}

.rowDescriptions {
  width: 12%;
  height: 100%;
  float: left;
  display: flex;
  flex-flow: column;
  text-align: left;
  line-height: 1;

}

.cavity,
.rowDescription {
  height: 10%;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-bottom: -1px;
  margin-right: -1px;
  white-space: nowrap;

}

.rowDescription .firstDay {
  margin-top: 4px;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: bold;
  color: var(--color-blue);
}

.rowDescription .date {
  font-size: 0.9rem;

}

.cavity {
  position: relative;
  text-transform: uppercase;
  padding: 0.5rem;
  border: 1px solid;
  border-color: var(--color-light-grey);
}

.dateText {
  font-size: 0.7rem;
  margin: 0.3rem 0;

}

.stateLabel {
  font-size: 0.7rem;
  padding: 2px 0;
  color: white;
  border-radius: 15px;
}


.column {
  width: 22%;
  height: 100%;
  float: left;
  text-align: center;
  display: flex;
  flex-flow: column;
}

.columnHeader {
  border: none;
  height: 92px;
  vertical-align: bottom;
  flex: 0 1 auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 10px;

}

.columnHeader img {
  width: 30%;
  margin-bottom: 5px;
}

.columnHeader span {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 1px 0;
}


.cavity.ok {
  background-color: var(--color-light-green);
  border-color: var(--color-green);
  color: var(--color-dark-green);
  z-index: 1;
}

.cavity.ok .stateLabel {
  background-color: var(--color-green);
}

.cavity.wrong {
  background-color: var(--color-light-yellow);
  border-color: var(--color-yellow);
  color: var(--color-dark-yellow);
  z-index: 2;
}

.cavity.wrong .stateLabel {
  background-color: var(--color-yellow);
}

.cavity.missed {
  background-color: var(--color-light-red);
  border-color: var(--color-red);
  color: var(--color-dark-red);
  z-index: 3;
  box-shadow: 0 0 10px -3px var(--color-red);
}

.cavity.missed .stateLabel {
  background-color: var(--color-red);
}

.cavity.suspended {
  background-color: var(--color-light-grey);
  border-color: var(--color-grey);
  color: var(--color-dark-grey);
  z-index: 3;
  box-shadow: 0 0 10px -3px var(--color-dark-grey);
}

.cavity.suspended .stateLabel {
  background-color: var(--color-dark-grey);
}

.userAction {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
}

.userAction.taken {
  background-color: var(--color-green);
}

.userAction.cancel {
  background-color: var(--color-red);
}

.userActionPopover {
  height: 0;
}
